import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button } from 'antd';
import { Select, Input } from 'formik-antd';
import { Formik, Form, ErrorMessage } from 'formik';

import API from '../../../../api';
import {
  FlexBeetwen,
  TwoBtnWrapper,
  FormSectionTitle,
} from '../../../common/styledComponents';
import isQERP from '../../../../hooks/isQerp';
import TagsSelector from './TagsSelector';
import { CenterAntSpin } from '../../../common/Spinners';
import { SpecificError } from '../../../common/Messages';
import { handleServerError } from '../../../../utils/errorHandlers';
import { filterOptions, restructArray } from '../../../../utils/search';

const { Option } = Select;
const fieldSize = 'large';

const tagsName = 'Tag';
const emailName = 'Email address';
const leaderName = 'Project leader';
const projectName = 'Project name';
const contactName = 'Customer project leader';
const locationName = 'Work location';
const phoneNumberName = 'Phone number';
const coordinatorName = 'Project coordinator';
const descriptionName = 'Project description';
const establishmentName = 'Establishment';
const projectNumberName = 'Project number';
const purchaseNumberName = 'Purchase number';

const rawValues = {
  [tagsName]: undefined,
  [emailName]: undefined,
  [leaderName]: undefined,
  [contactName]: undefined,
  [projectName]: undefined,
  [locationName]: undefined,
  [phoneNumberName]: undefined,
  [coordinatorName]: undefined,
  [descriptionName]: undefined,
  [establishmentName]: undefined,
  [projectNumberName]: undefined,
  [purchaseNumberName]: undefined,
};

const createRequestObj = values => {
  return {
    acquaintance_id: values.customerId,
    acquaintance_project_leader_id: values[contactName],
    acquaintance_project_leader_email: values[emailName],
    acquaintance_project_leader_phone_number: values[phoneNumberName],

    establishment_id: values[establishmentName],
    work_location_id: values[locationName],
    qis_project_leader_id: values[leaderName],
    qis_project_coordinator_id: values[coordinatorName],

    project_tag_id: values[tagsName],
    name: values[projectName],
    number: values[projectNumberName],
    description: values[descriptionName],
    purchase_number: values[purchaseNumberName],
  };
};

const validationSchema = isQerpUrl =>
  Yup.object().shape({
    [emailName]: Yup.string()
      .trim()
      .email()
      .required(),
    [contactName]: Yup.number().required(),
    [phoneNumberName]: Yup.string()
      .trim()
      .min(4)
      .required(),

    [leaderName]: isQerpUrl ? Yup.number().required() : Yup.number(),
    [locationName]: Yup.number().required(),
    [coordinatorName]: isQerpUrl ? Yup.number().required() : Yup.number(),
    [establishmentName]: Yup.number().required(),

    [tagsName]: Yup.string().required(),
    [projectName]: isQerpUrl
      ? Yup.string()
          .trim()
          .min(2)
          .required()
      : Yup.string()
          .trim()
          .min(2),
    [descriptionName]: isQerpUrl
      ? Yup.string()
          .trim()
          .required()
      : Yup.string().trim(),
    [projectNumberName]: isQerpUrl
      ? Yup.string()
          .trim()
          .required()
      : Yup.string().trim(),
    [purchaseNumberName]: isQerpUrl
      ? Yup.string()
          .trim()
          .required()
      : Yup.string().trim(),
  });

export default ({
  exit,
  prev,
  next,
  customerId,
  establishments,
  setEstablishmentId,
  customerContacts,
  preparePropsForWorkOrder,
  projectOptions: { leaders, coordinators, locations },
}) => {
  const isQerpUrl = isQERP(window.location.host);
  const initialValues = { ...rawValues, customerId };

  const [query, setQuery] = React.useState('');

  const handleContactChange = (value, formikValues, formikSetValues) => {
    const found = customerContacts.find(({ id }) => id === value);

    if (query) setQuery('');

    if (found) {
      formikSetValues({
        ...formikValues,
        [contactName]: value,
        [phoneNumberName]: found.phone_numbers[0],
        [emailName]: found.emails[0],
      });
    }
  };

  const createNewProject = async values => {
    try {
      const data = await API.postProject(values);
      return data;
    } catch (e) {
      handleServerError(e);
      return null;
    }
  };

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const payload = createRequestObj(values);

      const data = await createNewProject(payload);

      setSubmitting(false);

      if (data) {
        preparePropsForWorkOrder({
          id: data.project_id,
          tag: values[tagsName],
          name: values[projectName],
          email: values[emailName],
          phone: values[phoneNumberName],
          contactId: values[contactName],
          locationId: values[locationName],
          description: values[descriptionName],
          purchaseNumber: values[purchaseNumberName],
        });

        next();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(isQerpUrl)}
      onSubmit={submitHandler}
    >
      {({ isSubmitting, setValues, values }) => {
        return (
          <FluidContainer>
            <Form>
              <Grid>
                <div>
                  <FormSectionTitle>Customer data:</FormSectionTitle>

                  {customerContacts ? (
                    <WideSelect
                      name={contactName}
                      placeholder={contactName}
                      showSearch
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      notFoundContent={null}
                      size={fieldSize}
                      onSearch={value => setQuery(value)}
                      onChange={value => {
                        handleContactChange(value, values, setValues);
                      }}
                    >
                      {filterOptions(customerContacts, query, 'full_name').map(
                        ({ full_name: name, id }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        )
                      )}
                    </WideSelect>
                  ) : (
                    <CenterAntSpin />
                  )}
                  <SpecificError>
                    <ErrorMessage name={contactName} />
                  </SpecificError>

                  <Input
                    name={phoneNumberName}
                    size={fieldSize}
                    placeholder={phoneNumberName}
                  />
                  <SpecificError>
                    <ErrorMessage name={phoneNumberName} />
                  </SpecificError>

                  <Input
                    name={emailName}
                    size={fieldSize}
                    placeholder={emailName}
                  />
                  <SpecificError>
                    <ErrorMessage name={emailName} />
                  </SpecificError>
                </div>

                <div>
                  <FormSectionTitle>QIS Data:</FormSectionTitle>

                  <div
                    style={{
                      display: 'grid',
                      gridColumnGap: '10px',
                      gridTemplateColumns: '1fr 1fr',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      {establishments ? (
                        <WideSelect
                          name={establishmentName}
                          size={fieldSize}
                          placeholder={establishmentName}
                          showSearch
                          filterOption={false}
                          defaultActiveFirstOption={false}
                          notFoundContent={null}
                          onSearch={value => setQuery(value)}
                          onChange={value => {
                            setEstablishmentId(value);
                            setQuery('');
                            setValues({
                              ...values,
                              [leaderName]: undefined,
                              [locationName]: undefined,
                              [coordinatorName]: undefined,
                              [establishmentName]: value,
                            });
                          }}
                        >
                          {filterOptions(establishments, query, 'name').map(
                            ({ name, id }) => (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            )
                          )}
                        </WideSelect>
                      ) : (
                        <CenterAntSpin />
                      )}
                      <SpecificError>
                        <ErrorMessage name={establishmentName} />
                      </SpecificError>

                      {leaders ? (
                        <WideSelect
                          name={leaderName}
                          size={fieldSize}
                          placeholder={leaderName}
                          showSearch
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          notFoundContent={null}
                          onSearch={value => setQuery(value)}
                          onChange={() => {
                            setQuery('');
                          }}
                          disabled={!leaders.length}
                        >
                          {filterOptions(leaders, query, 'full_name').map(
                            ({ full_name: name, id }) => (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            )
                          )}
                        </WideSelect>
                      ) : (
                        <CenterAntSpin />
                      )}
                      <SpecificError>
                        <ErrorMessage name={leaderName} />
                      </SpecificError>

                      {coordinators ? (
                        <WideSelect
                          placeholder={coordinatorName}
                          showSearch
                          name={coordinatorName}
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          notFoundContent={null}
                          size={fieldSize}
                          onSearch={value => setQuery(value)}
                          onChange={() => {
                            setQuery('');
                          }}
                          disabled={!coordinators.length}
                        >
                          {filterOptions(coordinators, query, 'full_name').map(
                            ({ full_name: name, id }) => (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            )
                          )}
                        </WideSelect>
                      ) : (
                        <CenterAntSpin />
                      )}
                      <SpecificError>
                        <ErrorMessage name={coordinatorName} />
                      </SpecificError>

                      {locations ? (
                        <WideSelect
                          placeholder={locationName}
                          showSearch
                          name={locationName}
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          notFoundContent={null}
                          size={fieldSize}
                          onSearch={value => setQuery(value)}
                          onChange={() => {
                            setQuery('');
                          }}
                          disabled={!locations.length}
                        >
                          {filterOptions(
                            restructArray(
                              locations,
                              values[locationName],
                              'id'
                            ),
                            query,
                            'address'
                          )
                            .slice(0, 50)
                            .map(({ address, id }) => (
                              <Option key={id} value={id}>
                                {address}
                              </Option>
                            ))}
                        </WideSelect>
                      ) : (
                        <CenterAntSpin />
                      )}
                      <SpecificError>
                        <ErrorMessage name={locationName} />
                      </SpecificError>
                    </div>

                    <div style={{ width: '100%' }}>
                      <TagsSelector />

                      <Input
                        size={fieldSize}
                        placeholder={projectName}
                        name={projectName}
                      />
                      <SpecificError>
                        <ErrorMessage name={projectName} />
                      </SpecificError>

                      <Input
                        size={fieldSize}
                        placeholder={projectNumberName}
                        name={projectNumberName}
                      />
                      <SpecificError>
                        <ErrorMessage name={projectNumberName} />
                      </SpecificError>

                      <Input
                        size={fieldSize}
                        placeholder={purchaseNumberName}
                        name={purchaseNumberName}
                      />
                      <SpecificError>
                        <ErrorMessage name={purchaseNumberName} />
                      </SpecificError>

                      <Input
                        size={fieldSize}
                        placeholder={descriptionName}
                        name={descriptionName}
                      />
                      <SpecificError>
                        <ErrorMessage name={descriptionName} />
                      </SpecificError>
                    </div>
                  </div>
                </div>
              </Grid>

              <FlexBeetwen>
                <Button type="dashed" disabled={isSubmitting} onClick={exit}>
                  Exit
                </Button>

                <TwoBtnWrapper>
                  <Button onClick={prev}>Back</Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    Create project
                  </Button>
                </TwoBtnWrapper>
              </FlexBeetwen>
            </Form>
          </FluidContainer>
        );
      }}
    </Formik>
  );
};

const FluidContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 20px;
`;

const WideSelect = styled(Select)`
  width: 100%;
`;
