import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from 'antd';
import { FadeLoader } from 'react-spinners';

import H1 from '../../common/MainHeader';
import API from '../../../api';
import Modal from '../Modal';
import Delete from '../Deleting';
import CloseSvg from '../../../public/icons/close.svg';
import ProjectData from './ProjectData';
import CustomerData from './CustomerData';
import OtherEmloyees from './OtherEmployees';
import WorkLocationData from './WorkLocationData';
import { useRootContext } from '../../../context';
import { basic, primary } from '../../../constants/stylesConstants';
import { DeletePlanningBtnWithIcon } from '../../common/Button';
import {
  setActiveEmployee,
  removeAllActivities,
} from '../../../context/actions';
import {
  handleServerError,
  showErrorNotification,
} from '../../../utils/errorHandlers';
import { getServerUrl } from '../../../utils/apiUrls';
import { PageWrapper } from '../../common/styledComponents';
import { workOrderLoadError } from '../../../constants/errorsMsgs';
import {
  mapServerPattern,
  defaultInitPattern,
} from '../Recurrence/payloadMapping';

import Recurrence from '../Recurrence';
import useFetchData from '../../../hooks/useFetchData';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants/formats';
import Note from './Note';

const reqObjFromData = ({
  type,
  title,
  description,
  employee_id,
  work_order_id,
  start_point,
  end_point,
}) => {
  const [startDate, startTime] = start_point.split(' ');
  const [endDate, endTime] = end_point.split(' ');

  const reqObj = {
    type,
    title,
    description,
    employee_id,
    work_order_id,
    start_date: moment(startDate).format(DATE_FORMAT),
    start_time: moment(startTime, TIME_FORMAT).format(TIME_FORMAT),
    end_date: moment(endDate).format(DATE_FORMAT),
    end_time: moment(endTime, TIME_FORMAT).format(TIME_FORMAT),
  };

  return reqObj;
};

function updateNestedProperty(obj, path, value) {
  const keys = path.split('.');

  let currentPropertyValue = null;

  keys.forEach((key, index) => {
    if (index <= keys.length - 2) currentPropertyValue = obj[key];
  });

  currentPropertyValue[keys[keys.length - 1]] = value;

  return obj;
}

export default function WorkOrder({
  title,
  activityId,
  selectedEmployee,
  onHideModal,
}) {
  const {
    dispatch,
    state: { activeEmployee },
  } = useRootContext();

  const [isLoading, _isError, isEditable] = useFetchData(
    API.checkPermission,
    activityId
  );
  const [networkError, setNetworkError] = useState('');
  const [workOrderData, setWorkOrderData] = useState(null);
  const [employeesState, setEmployeesState] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [recurrentId, setRecurrentId] = useState(null);
  const [initPattern, setInitPattern] = useState(defaultInitPattern);
  const [isRecurrence, setIsRecurrence] = useState(false);
  const [recurrenceActivity, setRecurrenceActivity] = useState(null);

  const onRemoveAll = async () => {
    await removeAllActivities(
      dispatch,
      {
        work_order_id: workOrderData.activity.work_order_id,
      },
      showErrorNotification
    );
    onHideModal();
  };

  const serverUrl = getServerUrl();

  useEffect(() => {
    setActiveEmployee(dispatch, String(selectedEmployee));
  }, [selectedEmployee]);

  useEffect(() => {
    if (employeesState && !Object.keys(employeesState).length) {
      setTimeout(onHideModal, 200);
    }
  }, [employeesState]);

  const getWorkOrderData = useCallback(async id => {
    try {
      const data = await API.fetchWorkOrderData(id);
      await setEmployeesState(data.employees);
      await setWorkOrderData(data);
    } catch (e) {
      onHideModal();
      showErrorNotification(workOrderLoadError);
      setNetworkError(workOrderLoadError);
    }
  }, []);

  const updateWorkOrderData = (path, newValue) => {
    const dataCopy = JSON.parse(JSON.stringify(workOrderData));

    const updatedData = updateNestedProperty(dataCopy, path, newValue);

    setWorkOrderData(updatedData);
  };

  const getRecurrence = async () => {
    if (employeesState && activeEmployee && employeesState[activeEmployee]) {
      const recurring_activity = employeesState[activeEmployee].activities.find(
        ({ recurring_activity_id }) => !!recurring_activity_id === true
      );

      if (recurring_activity) {
        const { recurring_activity_id } = recurring_activity;
        try {
          const data = await API.getRecurrence(recurring_activity_id);

          setRecurrentId(recurring_activity_id);
          setRecurrenceActivity(data.recurring_activity.activities[0]);
          setInitPattern(mapServerPattern(data.recurring_activity));
        } catch (e) {
          handleServerError(e);
        }
      } else {
        setRecurrentId(null);
      }
    }
  };

  useEffect(() => {
    getRecurrence();
  }, [activeEmployee, employeesState]);

  useEffect(() => {
    getWorkOrderData(activityId);
  }, []);

  if (!workOrderData && isLoading) return <FadeLoader />;

  return (
    // !isUpdating &&
    workOrderData && (
      <ProjectCardModal onMouseDown={e => e.stopPropagation()}>
        <Title>
          <H1 type="modal">{title}</H1>
          <CloseIcon onClick={onHideModal} />
        </Title>

        {networkError && <PageWrapper>{networkError}</PageWrapper>}

        {!networkError && (
          <DataWrapper>
            <TopBar>
              <Note
                workOrderData={workOrderData}
                updateWorkOrderData={updateWorkOrderData}
              />

              <Actions>
                {isEditable && (
                  <Button
                    disabled={!recurrentId}
                    onClick={() => setIsRecurrence(true)}
                  >
                    Edit Recurring
                  </Button>
                )}

                <GoToWorkOrder
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${serverUrl}/work-orders/${workOrderData?.activity?.work_order_id}/`}
                >
                  Go to work order
                </GoToWorkOrder>

                {isEditable && (
                  <DeletePlanningBtnWithIcon
                    openDeleteModal={() => setIsDeleteModalVisible(true)}
                  >
                    Delete
                  </DeletePlanningBtnWithIcon>
                )}
              </Actions>
            </TopBar>

            <ProjectData projectData={workOrderData.work_order} />

            <CustomerData customerData={workOrderData.work_order} />

            <WorkLocationData workLocationData={workOrderData.work_order} />

            <OtherEmloyees
              isEditable={isEditable}
              workOrderId={workOrderData?.activity?.work_order_id}
              setEmployeesState={setEmployeesState}
              employees={employeesState || {}}
            />

            {isDeleteModalVisible && (
              <Modal onHideModal={() => setIsDeleteModalVisible(false)}>
                <Delete
                  resolveCallback={onRemoveAll}
                  rejectCallback={() => setIsDeleteModalVisible(false)}
                />
              </Modal>
            )}

            {isRecurrence && (
              <Modal onHideModal={() => setIsRecurrence(false)}>
                <Recurrence
                  {...initPattern}
                  recurrenceId={recurrentId}
                  activityReqObj={reqObjFromData(recurrenceActivity)}
                  hideCommon={onHideModal}
                  hideRucurrence={() => setIsRecurrence(false)}
                />
              </Modal>
            )}
          </DataWrapper>
        )}
      </ProjectCardModal>
    )
  );
}

const ProjectCardModal = styled.div`
  min-width: 1050px;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
  border-radius: 10px;
  overflow: hidden;
  background: ${basic};
`;

const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: ${primary};
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  cursor: pointer;
  right: 30px;
`;

const DataWrapper = styled.div`
  padding: 10px 30px;
`;

const TopBar = styled.div`
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Actions = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const GoToWorkOrder = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 700;
  color: ${basic};
  background: ${primary};
  border-radius: 3px;
  padding: 9px 20px;

  margin: 15px;

  &:hover {
    cursor: pointer;
    color: ${basic};
  }
`;
