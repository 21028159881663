import styled from 'styled-components';
import moment from 'moment';
import { Select, Radio, Checkbox, Row, Col, InputNumber } from 'antd';
import { text, secondaryBorderColor } from '../../../constants/stylesConstants';
import { MarginRight, MarginRightLeft } from '../../common/styledComponents';
import {
  WEEK_DAYS,
  MONTH_DAYS,
  MONTHS,
} from '../../../constants/datePatternsData';
import {
  normalizeNumberValue,
  validateWeekDays,
} from '../../../utils/validation';
import { SpecificError } from '../../common/Messages';

const { Option } = Select;

const Pattern = ({
  pattern,
  patternData,
  setPatternField,
  isSubmitting,
  weekDaysError,
  setWeekDaysError,
}) => {
  const {
    isDailyFirstOption,
    dayRecurrence,
    weekRecurrence,
    checkedDays,
    isMonthlyFirstOption,
    dayNumber,
    monthRecurrence,
    occurrenceDay,
    weekDay,
    isYearlyFirstOption,
    yearRecurrence,
    monthNumber,
  } = patternData;

  const renderPattern = (selectedPattern, submitting) => {
    switch (selectedPattern) {
      case 'daily': {
        return (
          <>
            <Radio.Group
              onChange={e => {
                setPatternField(e.target.value, 'isDailyFirstOption');
              }}
              value={isDailyFirstOption}
              disabled={submitting}
            >
              <FlexRowCenter>
                <StyledRadio value>Every</StyledRadio>
                <StyledInput
                  min={1}
                  size="small"
                  value={dayRecurrence}
                  disabled={submitting || !isDailyFirstOption}
                  onChange={value => {
                    setPatternField(
                      normalizeNumberValue(value),
                      'dayRecurrence'
                    );
                  }}
                />
                day(s)
              </FlexRowCenter>
              <StyledRadio value={false}>Every weekday</StyledRadio>
            </Radio.Group>
          </>
        );
      }

      case 'weekly': {
        return (
          <>
            <FlexRowCenter>
              Recur every
              <StyledInput
                min={1}
                value={weekRecurrence}
                onChange={value => {
                  setPatternField(
                    normalizeNumberValue(value),
                    'weekRecurrence'
                  );
                }}
                disabled={submitting}
                size="small"
              />
              week(s) on:
            </FlexRowCenter>

            <Checkbox.Group
              value={checkedDays}
              onChange={days => {
                validateWeekDays(days, setWeekDaysError, weekDaysError);
                setPatternField(days, 'checkedDays');
              }}
            >
              <Row>
                {WEEK_DAYS.map(({ label, value }) => (
                  <Col key={label} span={7}>
                    <Checkbox value={value}>{label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>

            <SpecificError>{weekDaysError}</SpecificError>
          </>
        );
      }

      case 'monthly': {
        return (
          <>
            <Radio.Group
              onChange={e => {
                setPatternField(e.target.value, 'isMonthlyFirstOption');
              }}
              value={isMonthlyFirstOption}
              disabled={submitting}
            >
              <FlexRowCenter>
                <StyledRadio value>Day</StyledRadio>
                <StyledInput
                  min={1}
                  max={31}
                  value={dayNumber}
                  disabled={submitting || !isMonthlyFirstOption}
                  onChange={value => {
                    setPatternField(normalizeNumberValue(value), 'dayNumber');
                  }}
                  size="small"
                />
                of every
                <StyledInput
                  min={1}
                  max={12}
                  value={isMonthlyFirstOption ? monthRecurrence : 1}
                  disabled={submitting || !isMonthlyFirstOption}
                  onChange={value => {
                    setPatternField(
                      normalizeNumberValue(value),
                      'monthRecurrence'
                    );
                  }}
                  size="small"
                />
                month(s)
              </FlexRowCenter>

              <FlexRowCenter>
                <StyledRadio value={false}>The</StyledRadio>
                <Select
                  value={occurrenceDay}
                  size="small"
                  onChange={value => {
                    setPatternField(
                      normalizeNumberValue(value),
                      'occurrenceDay'
                    );
                  }}
                  disabled={submitting || isMonthlyFirstOption}
                  style={{
                    width: 80,
                  }}
                >
                  {MONTH_DAYS.map(({ label, value }) => (
                    <Option key={label} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
                <MarginRightLeft>
                  <Select
                    value={weekDay}
                    onChange={value => {
                      setPatternField(normalizeNumberValue(value), 'weekDay');
                    }}
                    disabled={submitting || isMonthlyFirstOption}
                    size="small"
                    style={{
                      width: 110,
                    }}
                  >
                    {WEEK_DAYS.map(({ label, value }) => (
                      <Option key={label} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </MarginRightLeft>
                of every
                <StyledInput
                  min={1}
                  max={12}
                  value={!isMonthlyFirstOption ? monthRecurrence : 1}
                  disabled={submitting || isMonthlyFirstOption}
                  onChange={value => {
                    setPatternField(
                      normalizeNumberValue(value),
                      'monthRecurrence'
                    );
                  }}
                  size="small"
                />
                month(s)
              </FlexRowCenter>
            </Radio.Group>
          </>
        );
      }
      case 'yearly': {
        return (
          <>
            <FlexRowCenter>
              Recur every{' '}
              <StyledInput
                min={1}
                value={yearRecurrence}
                onChange={value => {
                  setPatternField(
                    normalizeNumberValue(value),
                    'yearRecurrence'
                  );
                }}
                disabled={submitting}
                size="small"
              />
              year(s)
            </FlexRowCenter>
            <Radio.Group
              onChange={e => {
                setPatternField(e.target.value, 'isYearlyFirstOption');
              }}
              value={isYearlyFirstOption}
              disabled={submitting}
            >
              <FlexRowCenter>
                <StyledRadio value>On:</StyledRadio>
                <MarginRightLeft>
                  <Select
                    value={monthNumber}
                    onChange={value => setPatternField(value, 'monthNumber')}
                    disabled={!isYearlyFirstOption || submitting}
                    size="small"
                    style={{
                      width: 110,
                    }}
                  >
                    {MONTHS.map(({ label, value }) => (
                      <Option key={label} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </MarginRightLeft>
                <StyledInput
                  min={1}
                  max={moment()
                    .month(monthNumber - 1)
                    .endOf('month')
                    .date()}
                  value={dayNumber}
                  onChange={value => {
                    setPatternField(normalizeNumberValue(value), 'dayNumber');
                  }}
                  disabled={!isYearlyFirstOption || submitting}
                  size="small"
                />
              </FlexRowCenter>

              <FlexRowCenter>
                <StyledRadio value={false}>On the:</StyledRadio>
                <MarginRight>
                  <Select
                    value={occurrenceDay}
                    onChange={value => setPatternField(value, 'occurrenceDay')}
                    disabled={isYearlyFirstOption || submitting}
                    size="small"
                    style={{
                      width: 80,
                    }}
                  >
                    {MONTH_DAYS.map(({ label, value }) => (
                      <Option key={label} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </MarginRight>

                <Select
                  value={weekDay}
                  onChange={value => setPatternField(value, 'weekDay')}
                  disabled={isYearlyFirstOption || submitting}
                  size="small"
                  style={{
                    width: 110,
                  }}
                >
                  {WEEK_DAYS.map(({ label, value }) => (
                    <Option key={label} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
                <MarginRightLeft>of</MarginRightLeft>

                <Select
                  value={monthNumber}
                  onChange={value => setPatternField(value, 'monthNumber')}
                  disabled={isYearlyFirstOption || submitting}
                  size="small"
                  style={{
                    width: 110,
                  }}
                >
                  {MONTHS.map(({ label, value }) => (
                    <Option key={label} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </FlexRowCenter>
            </Radio.Group>
          </>
        );
      }
      default:
        return null;
    }
  };
  return <Container>{renderPattern(pattern, isSubmitting)}</Container>;
};

const Container = styled.div`
  flex-basis: 80%;
  padding-left: 20px;
  border-left: 1px solid ${secondaryBorderColor};
`;

const FlexRowCenter = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  align-self: flex-start;
  color: ${text};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledRadio = styled(Radio)`
  height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  color: ${text};
`;

const StyledInput = styled(InputNumber)`
  margin: 0 5px;
  width: 60px;
`;

export default Pattern;
