import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useClickAway } from 'react-use';
import { useDebouncedCallback } from 'use-debounce/lib';

import { CaretDownOutlined } from '@ant-design/icons';

import AlertIcon from '../../../public/icons/alert.svg';
import SearchFilters from './SearchFilters';
import { secondary } from '../../../constants/stylesConstants';
import { useRootContext } from '../../../context';
import { searchWorkOrders } from '../../../context/actions';

const { Search } = Input;

export default () => {
  const filterRef = React.useRef(null);

  const [isActive, setIsActive] = React.useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);

  const onFiltersPress = () => {
    setIsFiltersOpen(prevState => !prevState);
  };

  useClickAway(filterRef, e => {
    if (
      e.target.classList.contains('ant-select-item') ||
      e.target.classList.contains('ant-select-item-option-content')
    )
      return;

    setIsFiltersOpen(false);
  });

  const {
    dispatch,
    state: {
      workOrderLocationFilters,
      workOrderCustomerFilters,
      workOrderEstablishmentFilters,
    },
  } = useRootContext();

  React.useEffect(() => {
    const isFiltersActive =
      workOrderLocationFilters.length ||
      workOrderCustomerFilters.length ||
      workOrderEstablishmentFilters.length;

    setIsActive(!!isFiltersActive);
  }, [
    workOrderLocationFilters,
    workOrderCustomerFilters,
    workOrderEstablishmentFilters,
  ]);

  const [debouncedInput] = useDebouncedCallback(query => {
    searchWorkOrders(dispatch, query.toLowerCase());
  }, 1000);

  return (
    <SearchPanel ref={filterRef}>
      <SearchInput>
        <SearchAnt
          placeholder="Search"
          onChange={({ target }) => debouncedInput(target.value)}
        />

        <Flex>
          {isActive && <StyledAlert />}

          <OpenFilters onClick={onFiltersPress}>
            <MarginRight>Filters</MarginRight>
            <CaretDownOutlined />
          </OpenFilters>

          {isActive && <StyledAlert />}
        </Flex>
      </SearchInput>

      {isFiltersOpen && (
        <Absolute>
          <SearchFilters onMouseDown={e => e.stopPropagation()} />
        </Absolute>
      )}
    </SearchPanel>
  );
};

const MarginRight = styled.div`
  margin-right: 10px;
`;

const SearchPanel = styled.div`
  position: relative;
  width: 500px;
  font-size: 14px;
`;

const SearchInput = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
`;

const OpenFilters = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SearchAnt = styled(Search)`
  max-width: 275px;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const Absolute = styled.div`
  position: absolute;
  background-color: ${secondary};
  width: 100%;
  transform: translateY(100%);
  z-index: 1000;
  bottom: -4px;
  border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  right: 0;
`;

const StyledAlert = styled(AlertIcon)`
  width: 20px;
  height: 20px;
`;
