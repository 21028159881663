import { useEffect } from 'react';

const useSyntScrollListener = (flag, handler) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (flag) {
      document.addEventListener('syntscroll', handler);
    } else {
      document.removeEventListener('syntscroll', handler);
    }

    return () => document.removeEventListener('syntscroll', handler);
  }, [flag]);
};

export default useSyntScrollListener;
