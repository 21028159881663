import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { useRootContext } from '../../../context';
import {
  changeWOLocationFilter,
  changeWOCustomerFilter,
  changeWOEstablishmentFilter,
} from '../../../context/actions';
import { transition } from '../../../constants/stylesConstants';

const { Option } = Select;

export default () => {
  const {
    state: {
      dates,
      workOrdersLocations,
      workOrdersCustomers,
      workOrdersEstablishments,
      workOrderLocationFilters,
      workOrderCustomerFilters,
      workOrderEstablishmentFilters,
    },
    dispatch,
  } = useRootContext();

  const handleLocationChange = locations =>
    changeWOLocationFilter(dispatch, locations);
  const handleCustomerChange = customers =>
    changeWOCustomerFilter(dispatch, customers);
  const handleEstablishmentChange = establishments =>
    changeWOEstablishmentFilter(dispatch, establishments);

  const onResetAllFilters = () => {
    changeWOLocationFilter(dispatch, []);
    changeWOCustomerFilter(dispatch, []);
    changeWOEstablishmentFilter(dispatch, []);
  };

  useEffect(() => {
    const isFiltersActive =
      workOrderLocationFilters.length ||
      workOrderCustomerFilters.length ||
      workOrderEstablishmentFilters.length;

    if (!isFiltersActive) {
      handleLocationChange([]);
      handleCustomerChange([]);
      handleEstablishmentChange([]);
    }
  }, [dates]);

  return (
    <SearchFilters>
      <Flex>
        <ReseltAllBtn onClick={onResetAllFilters}>
          Reset all filters
        </ReseltAllBtn>
      </Flex>

      <MarginBottom>
        <FilterCategory>Customer</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Customer"
          value={workOrderCustomerFilters}
          onChange={handleCustomerChange}
        >
          {workOrdersCustomers.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>
      <MarginBottom>
        <FilterCategory>Establishments</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Establishments"
          value={workOrderEstablishmentFilters}
          onChange={handleEstablishmentChange}
        >
          {workOrdersEstablishments.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>

      <MarginBottom>
        <FilterCategory>Work Location</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Work Location"
          value={workOrderLocationFilters}
          onChange={handleLocationChange}
        >
          {workOrdersLocations.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>
    </SearchFilters>
  );
};

const SearchFilters = styled.div`
  padding: 0px 20px;
  padding-bottom: 25px;
  overflow: hidden;
`;

const MarginBottom = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }

  &:first-child {
    padding-top: 15px;
  }
  /* Interrupting default ant styles for Select */

  .ant-select-lg {
    width: 100%;
  }

  .ant-select-selection__choice {
    background: #ececec;
    border-radius: 3px;
  }

  .ant-select-selection__choice__remove {
    color: #b1b1b1;
    font-size: 14px;
  }

  .ant-select-selection__choice__content {
    color: #b1b1b1;
  }
`;

const FilterCategory = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
`;

const Flex = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
`;

const ReseltAllBtn = styled.button`
  background: transparent;
  outline: none;
  border: none;
  transition: ${transition};
  &:hover {
    color: red;
  }
`;
