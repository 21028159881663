import { useEffect, useState } from 'react';

const useFetchData = (callback, reqArgument) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    let fetchedData = null;

    try {
      fetchedData = await callback(reqArgument);
      if (fetchedData.response) {
        setData(fetchedData.response);
        setIsLoading(false);
        return;
      }
      setData(fetchedData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (reqArgument) getData();
  }, [reqArgument]);

  return [isLoading, isError, data];
};

export default useFetchData;
