import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'formik-antd';
import { Button } from 'antd';
import * as Yup from 'yup';
import API from '../../../../api';
import { SpecificError } from '../../../common/Messages';
import { handleServerError } from '../../../../utils/errorHandlers';
import {
  FlexBeetwen,
  FormSectionTitle,
} from '../../../common/styledComponents';
import { Container } from './CustomerSelector';

const companyName = 'Company name';
const firstName = 'First name';
const lastName = 'Last name';
const phoneNumber = 'Phone number';

const initialValues = {
  [companyName]: '',
  [firstName]: '',
  [lastName]: '',
  [phoneNumber]: '',
};

const validationSchema = Yup.object().shape({
  [companyName]: Yup.string()
    .trim()
    .required(),
  [firstName]: Yup.string()
    .trim()
    .required(),
  [lastName]: Yup.string()
    .trim()
    .required(),
  [phoneNumber]: Yup.string()
    .trim()
    .required(),
});

export default ({
  exit,
  next,
  setCustomerId,
  pushNewCustomer,
  isNewCustomer,
  setIsNewCustomer,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          const res = await API.postCustomer({
            name: values[companyName],
            first_name: values[firstName],
            last_name: values[lastName],
            phone_number: values[phoneNumber],
          });
          if (!isNewCustomer) setIsNewCustomer(true);
          setCustomerId(res.acquaintance_id);
          pushNewCustomer({
            id: res.acquaintance_id,
            name: values[companyName],
          });
          next();
        } catch (e) {
          handleServerError(e);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Container>
            <Form>
              <FormSectionTitle>company:</FormSectionTitle>
              <Input
                size="large"
                placeholder={companyName}
                name={companyName}
              />
              <SpecificError>
                <ErrorMessage name={companyName} />
              </SpecificError>

              <FormSectionTitle>contact person:</FormSectionTitle>
              <Input size="large" placeholder={firstName} name={firstName} />
              <SpecificError>
                <ErrorMessage name={firstName} />
              </SpecificError>
              <Input size="large" placeholder={lastName} name={lastName} />
              <SpecificError>
                <ErrorMessage name={lastName} />
              </SpecificError>
              <Input
                size="large"
                placeholder={phoneNumber}
                name={phoneNumber}
              />
              <SpecificError>
                <ErrorMessage name={phoneNumber} />
              </SpecificError>
              <FlexBeetwen>
                <Button onClick={exit} type="dashed" disabled={isSubmitting}>
                  Exit
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  Create customer
                </Button>
              </FlexBeetwen>
            </Form>
          </Container>
        );
      }}
    </Formik>
  );
};
