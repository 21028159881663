import styled from 'styled-components';
import Row from './Row';

const titles = [
  'Employee',
  'Start date',
  'End date',
  'Start time',
  'End time',
  'Edit',
  'Delete',
];

const EmployeesTable = ({ isEditingAllowed, setRows, rows }) => {
  return (
    <Container>
      <TableHeader>
        {titles.map(title => (
          <Col key={title}>{title}</Col>
        ))}
      </TableHeader>

      <div>
        {rows.map((row, i) => (
          <Row
            key={row.employeeId}
            rows={rows}
            index={i}
            setRows={setRows}
            isEditingAllowed={isEditingAllowed}
            {...row}
          />
        ))}
      </div>
    </Container>
  );
};

export default EmployeesTable;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
`;
const Col = styled.div`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  border-left: 1px solid #e9e9e9;
  padding: 10px;
`;

const Container = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #00bfff;
  border-radius: 2px;
`;
