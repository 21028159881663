import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { VscCircleFilled } from 'react-icons/vsc';

import Modal from '../ModalWindows/Modal';
import {
  getViewXW,
  transformDatesToX,
  transformIndexToY,
} from '../../coreFuncs/transfomation';
import {
  DATE_TIME_FORMAT,
  MOMENT_DATE_TIME_FORMAT,
} from '../../constants/formats';
import { titleTaskColor } from '../../constants/stylesConstants';
import { BLOCK_HEIGHT, MIN_WIDTH } from '../../constants/continuum';
import { dragAndDrop, detectEdge, resize } from '../../coreFuncs/interaction';
import {
  isSectorClear,
  areRectsIntersecting,
} from '../../coreFuncs/overlaping';
import Conflict from '../ModalWindows/Conflict';
import ContextMenu from './ContextMenu';
import DeletingModal from '../ModalWindows/Deleting';
import WorkOrderModal from '../ModalWindows/WorkOrder';
import { useRootContext } from '../../context';
import globalMutableObject from '../../context/globalMutableStore';
import CommonActivityModal from '../ModalWindows/Common';
import useSyntScrollListener from '../../hooks/useSyntScrollListener';
import { updateActivity, removeActivity } from '../../context/actions';

const padding = 7;
const approxSymbolW = 7.5;

function TaskBlock(props) {
  const {
    id,
    type,
    city,
    title,
    color,
    borderBottomColor,
    board,
    customer,
    endPoint,
    zeroDate,
    dispatch,
    employees,
    startPoint,
    customerId,
    dimensions,
    description,
    workOrderId,
    workOrderNote,
    recurrenceId,
    calendarWidth,
    workLocationId,
    employeePosition,
  } = props || {};

  /* eslint-disable react-hooks/rules-of-hooks */
  if (typeof employeePosition !== 'number') return <></>;

  const {
    state: { allowedEmployees, activities, isUpdateAll, visibleEmployees },
  } = useRootContext();

  const ref = useRef(null);

  const [XYW, setXYW] = useState({});
  const [edge, setEdge] = useState(null);
  const [isInFrame, setIsInFrame] = useState(true);
  const [sectorClear, setSectorClear] = useState(true);
  const [conflictTasks, setConflictTasks] = useState([]);
  const [contextMenuXY, setContextMenuXY] = useState(null);

  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const [isCopyModalShown, setIsCopyModalShown] = useState(false);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [isDeletingModalShown, setIsDeletingModalShown] = useState(false);
  const [isWorkOrderModalShown, setIsWorkOrderModalShown] = useState(false);

  const employeeId = useMemo(() => employees[employeePosition].id, [
    employees,
    employeePosition,
  ]);

  const stylesObj = useMemo(() => {
    return {
      top: `${XYW.y}px`,
      left: `${XYW.x}px`,
      width: `${XYW.w}px`,
    };
  }, [XYW, dimensions]);

  const taskTitle = useMemo(() => {
    return workOrderId ? `${customer} / ${title} / ${city}` : title;
  }, [title, workOrderId]);

  const titleOnHover = useMemo(() => {
    return taskTitle.length * approxSymbolW > XYW.w - padding * 2
      ? taskTitle
      : null;
  }, [taskTitle, XYW.w]);

  const className = useMemo(
    () =>
      sectorClear
        ? `rectangle employee-${employeeId}`
        : `rectangle employee-${employeeId} conflict`,

    [employeeId, sectorClear]
  );

  const closeConflictModal = useCallback(() => {
    setConflictTasks([]);
  }, [employeeId]);

  const openConflictModal = useCallback(tasks => {
    setConflictTasks(tasks);
  }, []);

  // eslint-disable-next-line consistent-return
  const foundConflictTasks = (self, emplId) => {
    const tasksId = [Number(self.id)];
    const collection = document.getElementsByClassName(`employee-${emplId}`);

    if (!self) return [];

    const box = self.getBoundingClientRect();
    // eslint-disable-next-line consistent-return
    [].forEach.call(collection, nearby => {
      if (nearby.id === self.id) return false;

      if (areRectsIntersecting(box, nearby.getBoundingClientRect())) {
        tasksId.push(Number(nearby.id));
      }
    });

    if (tasksId.length === 1) return [];

    const tasks = tasksId.map(taskId => activities.find(a => a.id === taskId));

    openConflictModal(tasks);
  };

  const closeDeletingModal = useCallback(() => {
    setIsDeletingModalShown(false);
  }, []);
  const openDeletingModal = useCallback(() => {
    setIsDeletingModalShown(true);
  }, []);
  const closeCopyModal = useCallback(() => {
    setIsCopyModalShown(false);
  }, []);
  const openCopyModal = useCallback(() => {
    setIsCopyModalShown(true);
  }, []);
  const closeEditModal = useCallback(() => {
    setIsEditModalShown(false);
  }, []);
  const closeWorkOrderModal = useCallback(() => {
    setIsWorkOrderModalShown(false);
  }, []);

  const onClickCallback = useCallback(() => {
    if (ref.current.classList.contains('conflict')) {
      foundConflictTasks(ref.current, employeeId);
      return;
    }

    if (type === 2) setIsWorkOrderModalShown(true);
    else setIsEditModalShown(true);
  }, [type]);

  const closeContextMenu = useCallback(() => setContextMenuXY(null), []);

  const openContextMenu = useCallback(
    e => {
      e.preventDefault();

      const { clientX, clientY } = e;

      if (!contextMenuXY) setContextMenuXY({ clientX, clientY });
    },
    [contextMenuXY]
  );

  const destroySelf = useCallback(() => {
    removeActivity(dispatch, id, false, activities, visibleEmployees);
  }, []);

  const createReqObj = useCallback(
    (start, end, emplId) => {
      const [newStartDate, newStartTime] = moment(start)
        .format(DATE_TIME_FORMAT)
        .split(' ');
      const [newEndDate, newEndTime] = moment(end)
        .format(DATE_TIME_FORMAT)
        .split(' ');

      const reqObj = {
        id,
        type,
        title,
        description,
        employee_id: emplId,
        end_date: newEndDate,
        end_time: newEndTime,
        start_date: newStartDate,
        start_time: newStartTime,
        work_order_id: workOrderId,
      };

      if (type === 8) {
        reqObj.customer_id = customerId;
        reqObj.work_location_id = workLocationId;
      }

      return reqObj;
    },
    [title, type, description, workOrderId]
  );

  const onDropCallback = useCallback(
    async ({ start, end, employeeIndex }) => {
      const dataSet = new Set([
        endPoint,
        startPoint,
        employeeIndex,
        employeePosition,
        end.format(MOMENT_DATE_TIME_FORMAT),
        start.format(MOMENT_DATE_TIME_FORMAT),
      ]);

      if (dataSet.size < 4) return true;

      const employee = employees[employeeIndex];

      if (!employee) return false;

      const reqObj = createReqObj(start, end, employee.id);
      const success = await updateActivity(dispatch, reqObj);

      return !!success;
    },
    [zeroDate, endPoint, employees, startPoint, createReqObj, employeePosition]
  );

  const resizeCallback = useCallback(
    async newPoint => {
      const dataSet = new Set([
        endPoint,
        startPoint,
        newPoint.format(MOMENT_DATE_TIME_FORMAT),
      ]);

      if (dataSet.size < 3) return true;

      const reqObj =
        edge === 'right'
          ? createReqObj(startPoint, newPoint, employeeId)
          : createReqObj(newPoint, endPoint, employeeId);

      const success = await updateActivity(dispatch, reqObj);
      return !!success;
    },
    [zeroDate, startPoint, endPoint, employeeId, createReqObj, edge]
  );

  const mouseMoveHandler = useCallback(
    e => {
      const detectedEdge = detectEdge(e);
      if (detectedEdge !== edge) setEdge(detectedEdge);
    },
    [edge]
  );

  const mouseDownHandler = useCallback(
    e => {
      globalMutableObject.activeId = id;

      e.stopPropagation();

      if (edge) {
        resize({
          eDown: e,
          edge,
          zeroDate,
          dimensions,
          employeeId,
          board: board.current,
          callback: resizeCallback,
          allowedEmployees,
          isUpdateAll,
        });
      } else
        dragAndDrop({
          eDown: e,
          zeroDate,
          employees,
          employeeId,
          dimensions,
          onDropCallback,
          onClickCallback,
          clickDuration: 250,
          board: board.current,
          allowedEmployees,
          isUpdateAll,
        });
    },
    [
      edge,
      board,
      isUpdateAll,
      employeeId,
      dimensions,
      resizeCallback,
      onDropCallback,
      onClickCallback,
      allowedEmployees,
    ]
  );

  useEffect(() => {
    const { minuteW, stepW, colW, borderW, rowH, topPadding } = dimensions;

    const y = transformIndexToY(employeePosition, rowH, topPadding);

    const { timeX, timeW } = transformDatesToX(
      zeroDate,
      startPoint,
      endPoint,
      minuteW
    );

    const { viewX, viewW } = getViewXW(timeX, timeW, stepW, colW, borderW);

    setXYW({ x: viewX, y, w: viewW });
  }, [zeroDate, employeePosition, startPoint, endPoint, dimensions]);

  useEffect(() => {
    const nextIsInFrame =
      (XYW.x > 0 && XYW.x + XYW.w < calendarWidth) ||
      globalMutableObject.activeId === id;

    if (nextIsInFrame !== isInFrame) setIsInFrame(nextIsInFrame);
  }, [XYW, calendarWidth]);

  useLayoutEffect(() => {
    setTimeout(() => {
      const nextSectorClear = isSectorClear(ref.current, employeeId);
      if (nextSectorClear !== sectorClear) setSectorClear(nextSectorClear);
    });
  }, [XYW, employeeId]);

  useSyntScrollListener(contextMenuXY, closeContextMenu);

  return (
    <>
      {isInFrame && (
        <Block
          id={id}
          ref={ref}
          edge={edge}
          color={color}
          borderBottomColor={borderBottomColor}
          style={stylesObj}
          cursor="grab"
          className={className}
          onDragStart={() => false}
          onMouseMove={mouseMoveHandler}
          onMouseDown={mouseDownHandler}
          onContextMenu={openContextMenu}
        >
          {workOrderNote && (
            <NoteIconWrapper
              style={{ position: 'relative' }}
              onMouseEnter={() => {
                setIsNotesVisible(true);
              }}
              onMouseLeave={() => {
                setIsNotesVisible(false);
              }}
            >
              <VscCircleFilled />

              {isNotesVisible && (
                <Note top={0} left={XYW.w + 10}>
                  {workOrderNote?.content || 'Unknown'}
                </Note>
              )}
            </NoteIconWrapper>
          )}

          <TaskTitle title={titleOnHover} style={{ maxWidth: `${XYW.w}px` }}>
            {taskTitle}
          </TaskTitle>
        </Block>
      )}

      {!isInFrame && (
        <Block
          id={id}
          ref={ref}
          edge={false}
          color={color}
          borderBottomColor={borderBottomColor}
          style={stylesObj}
          cursor="pointer"
          className={className}
          onClick={onClickCallback}
          onDragStart={() => false}
          onMouseDown={e => e.stopPropagation()}
          onContextMenu={openContextMenu}
        >
          {workOrderNote && (
            <NoteIconWrapper
              style={{ position: 'relative' }}
              onMouseEnter={() => {
                setIsNotesVisible(true);
              }}
              onMouseLeave={() => {
                setIsNotesVisible(false);
              }}
            >
              <VscCircleFilled />

              {isNotesVisible && (
                <Note top={0} left={XYW.w + 10}>
                  {workOrderNote?.content || 'Unknown'}
                </Note>
              )}
            </NoteIconWrapper>
          )}

          <TaskTitle title={title} style={{ maxWidth: `${XYW.w}px` }}>
            {taskTitle}
          </TaskTitle>
        </Block>
      )}

      {!!contextMenuXY && (
        <ContextMenu
          id={id}
          closeSelf={closeContextMenu}
          coordinates={contextMenuXY}
          openCopyModal={openCopyModal}
          openDeletingModal={openDeletingModal}
        />
      )}

      {isDeletingModalShown && (
        <Modal onHideModal={closeDeletingModal}>
          <DeletingModal
            rejectCallback={closeDeletingModal}
            resolveCallback={destroySelf}
          />
        </Modal>
      )}

      {isCopyModalShown && (
        <Modal onHideModal={closeCopyModal}>
          <CommonActivityModal
            type={type}
            title={title}
            modalType="plan"
            employeeId={employeeId}
            onHideModal={closeCopyModal}
            description={description}
            workOrderId={workOrderId}
            customerId={customerId}
            workLocationId={workLocationId}
            dates={{ start: startPoint, end: endPoint }}
          />
        </Modal>
      )}

      {isEditModalShown && (
        <Modal onHideModal={closeEditModal}>
          <CommonActivityModal
            activityId={id}
            recurrenceId={recurrenceId}
            type={type}
            title={title}
            modalType="edit"
            employeeId={employeeId}
            description={description}
            workOrderId={workOrderId}
            customerId={customerId}
            workLocationId={workLocationId}
            onHideModal={closeEditModal}
            dates={{ start: startPoint, end: endPoint }}
          />
        </Modal>
      )}

      {isWorkOrderModalShown && (
        <Modal onHideModal={closeWorkOrderModal}>
          <WorkOrderModal
            title={taskTitle}
            activityId={id}
            selectedEmployee={employeeId}
            onHideModal={closeWorkOrderModal}
          />
        </Modal>
      )}

      {!!conflictTasks.length && (
        <Modal onHideModal={closeConflictModal}>
          <Conflict
            conflicts={conflictTasks}
            hideConflictModal={closeConflictModal}
          />
        </Modal>
      )}
    </>
  );
}

export default memo(TaskBlock);

const Block = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'edge' && prop !== 'borderBottomColor', // Filter out
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${BLOCK_HEIGHT}px;
  min-width: ${MIN_WIDTH}px;
  cursor: ${p => (p.edge ? 'ew-resize' : p.cursor)};
  text-align: center;
  background: ${p => p.color || 'olive'};
  border-radius: 10px;
  border-bottom: ${({ borderBottomColor }) =>
    borderBottomColor ? `5px solid ${borderBottomColor}` : '0'};
`;

const NoteIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    position: absolute;
    top: 0;
    right: 0;

    path {
      fill: #e74c3c;
    }
  }
`;

const TaskTitle = styled.span`
  display: inline-block;
  overflow: hidden;
  padding: 0 2px;
  margin: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${titleTaskColor};
  font-size: 14px;
  text-align: center;
  font-weight: 700;
`;

const Note = styled.div`
  z-index: 1000;
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${BLOCK_HEIGHT * 2}px;
  min-width: 200px;
  max-height: ${BLOCK_HEIGHT * 2}px;
  overflow: hidden auto;
  padding: 5px;
  border: 1px solid #daa520;
  font-size: 12px;
  text-align: justify;
  border-radius: 6px;
  background-color: #fffacd;
`;
