import React from 'react';
import styled from 'styled-components';

import isQERP from '../../../hooks/isQerp';
import {
  basic,
  primary,
  secondaryBorderColor,
} from '../../../constants/stylesConstants';

export default function WorkLocationData({ workLocationData }) {
  const {
    work_location,
    reports_remarks,
    work_order_location_requirements,
  } = workLocationData;

  const {
    city,
    street,
    postal_code,
    coordinates,
    house_number,
  } = work_location;

  const isQerpUrl = isQERP(window.location.host);

  return (
    <ProjectData>
      <Flex>
        <Container>
          <H4>Work Location Data</H4>

          <Row>
            <Col>
              <Title>Name:</Title>
            </Col>

            <DescriptionUpperText>{work_location.name}</DescriptionUpperText>
          </Row>

          <Row>
            <Col>
              <Title>Address:</Title>
            </Col>

            <FlexColumn>
              <Description>{`${street} ${house_number} ${postal_code} ${city}`}</Description>

              {coordinates && (
                <ShowInMapsLink
                  rel="noopener noreferrer"
                  href={`https://maps.google.com/?q=${coordinates[0]},${coordinates[1]}`}
                  target="_blank"
                >
                  Show in maps
                </ShowInMapsLink>
              )}
            </FlexColumn>
          </Row>
        </Container>

        <Container>
          <H4>Work Location Requirements</H4>

          <Row>
            <DescriptionWrapper>
              {work_order_location_requirements
                ? work_order_location_requirements
                    .split('\n')
                    .map(record =>
                      record.length ? <p key={record}>{record}</p> : null
                    )
                : null}
            </DescriptionWrapper>
          </Row>
        </Container>
      </Flex>

      {reports_remarks && (
        <Flex>
          <Container>
            <H4>{isQerpUrl ? 'Work description' : 'Report Remarks'}</H4>

            <Row>
              <DescriptionWrapper>
                {reports_remarks
                  .split('\n')
                  .map(record =>
                    record.length ? <p key={record}>{record}</p> : null
                  )}
              </DescriptionWrapper>
            </Row>
          </Container>
        </Flex>
      )}
    </ProjectData>
  );
}

const ProjectData = styled.div`
  position: relative;

  ::before {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 2px;
    height: 100%;
    background: ${secondaryBorderColor};
  }
`;

const H4 = styled.h4`
  font-size: 18px;
  font-weight: 700;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 2px solid ${secondaryBorderColor};
`;

const Container = styled.div`
  flex-basis: 50%;

  &:first-child {
    padding-right: 10px;
  }

  &:nth-child(2) {
    padding-left: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  margin: 10px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Col = styled.div`
  flex-basis: 25%;
  max-width: 25%;
`;

const Title = styled.p`
  font-size: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 495px;
  min-height: 100px;
  max-height: 100px;
  overflow: hidden auto;
  word-break: break-all;

  p {
    margin-bottom: 8px;
    font-size: 14px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  font-size: 16px;
  vertical-align: middle;
  overflow-wrap: break-word;
`;

const DescriptionUpperText = styled(Description)`
  text-transform: uppercase;
`;

const ShowInMapsLink = styled.a`
  margin-top: 10px;
  padding: 5px 11px;
  flex-shrink: 0;
  background: ${primary};
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
  color: ${basic};
  outline: none;
  border: none;
  text-align: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;
