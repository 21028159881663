import { useState } from 'react';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Select, Radio, InputNumber, Checkbox } from 'formik-antd';
import moment from 'moment';
import { Button } from 'antd';
import { useRootContext } from '../../../../context';
import { simplifyEmployees } from '../../Common';
import API from '../../../../api';
import {
  handleServerError,
  showSuccessNotification,
} from '../../../../utils/errorHandlers';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/formats';
import { FlexBeetwen, TwoBtnWrapper } from '../../../common/styledComponents';
import { createActivity } from '../../../../context/actions';
import { errorStyles } from '../../../common/Messages';

const { Option } = Select;

const findEmployees = (employees, rows) => {
  const foundedEmployees = [];
  rows.forEach(({ employeeId }) => {
    const foundedEmployee = employees.find(({ id }) => employeeId === id);
    if (foundedEmployee) foundedEmployees.push(foundedEmployee);
  });
  return foundedEmployees;
};

const inspector = 'Inspector';
const rtDeviceType = 'Device';
const ySource = 'y Source';
const transport = 'Transoptation of source needed?';
const gammaSourceId = 'Gamma Source Number';
const from = 'From Location';
const to = 'To Location';
const rtTubeId = 'RT Tube Number';
const voltage = 'Voltage';
const shots = 'Shots';

const initialValues = {
  [inspector]: undefined,
  [rtDeviceType]: undefined,
  [ySource]: undefined,
  [transport]: undefined,
  [gammaSourceId]: undefined,
  [from]: undefined,
  [to]: undefined,
  [rtTubeId]: undefined,
  [voltage]: undefined,
  [shots]: undefined,
};

const createRequestObj = (values, step1Data) => {
  const reqObject = {
    ...step1Data,
    rt_inspector_id: values[inspector],
    rt_device_type: values[rtDeviceType],
    recordings: values[shots],
  };

  if (values[rtDeviceType] === 1) {
    reqObject.rt_tube_id = values[rtTubeId];
    reqObject.voltage = values[voltage];
  }

  if (values[rtDeviceType] === 2) {
    reqObject.gamma_source_id = values[gammaSourceId];
    reqObject.source_transportation_required = values[transport];
  }

  if (values[transport]) {
    reqObject.transport_from_establishment_id = values[from];
    reqObject.transport_to_establishment_id = values[to];
  }

  return reqObject;
};

const validationSchema = Yup.object().shape({
  [inspector]: Yup.number().required('required'),
  [rtDeviceType]: Yup.number().required('required'),
  [ySource]: Yup.string().when(rtDeviceType, {
    is: 2,
    then: Yup.string().required('Please, make your choice'),
    otherwise: Yup.string().nullable(),
  }),
  [transport]: Yup.boolean(),
  [from]: Yup.number().when(transport, {
    is: true,
    then: Yup.number().required('required'),
    otherwise: Yup.number().nullable(),
  }),
  [to]: Yup.number().when(transport, {
    is: true,
    then: Yup.number().required('required'),
    otherwise: Yup.number().nullable(),
  }),
  [rtTubeId]: Yup.number().when(rtDeviceType, {
    is: 1,
    then: Yup.number().required('required'),
    otherwise: Yup.number().nullable(),
  }),
  [gammaSourceId]: Yup.number().when(rtDeviceType, {
    is: 2,
    then: Yup.number().required('required'),
    otherwise: Yup.number().nullable(),
  }),
  [voltage]: Yup.string().when(rtDeviceType, {
    is: 1,
    then: Yup.string().required('required'),
    otherwise: Yup.string().nullable(),
  }),
  [shots]: Yup.number()
    .typeError('must be a number')
    .required('required'),
});

const RadiographicFormik = ({
  handleSubmit,
  prev,
  exit,
  rows,
  finish2,
  step1Data,
  setIsEditingAllowed,
  createActivityReqObj,
  selectedSpecifications,
  saveExucationSpecification,
}) => {
  const {
    state: { employees },
    dispatch,
  } = useRootContext();

  const [numbers, setNumbers] = useState(null);
  const [voltages, setVoltages] = useState(null);
  const [ySourceData, setYSourceData] = useState(null);
  const [establishments, setEstablishments] = useState(null);
  const [workOrderData, setWorkOrderData] = useState(null);
  const simpleEmployees = simplifyEmployees(findEmployees(employees, rows));

  const fetchRTTubes = async () => {
    try {
      const data = await API.getRTTubeSets();
      const { set_number: setNumber, voltage: v } = data;
      setNumbers(setNumber);
      setVoltages(v);
    } catch (e) {
      handleServerError(e);
    }
  };

  const fetchYSource = async () => {
    try {
      const data = await API.getYSourceSets(moment().format(DATE_FORMAT));
      setYSourceData(data);
    } catch (e) {
      handleServerError(e);
    }
  };

  const fetchEstablishment = async () => {
    if (!establishments) {
      try {
        const data = await API.getProjectEstablishments();
        setEstablishments(data.establishments);
      } catch (e) {
        handleServerError(e);
      }
    }
  };

  const createNewWorkOrder = async (
    workOrder,
    values,
    activity,
    exucations,
    setSubmitting
  ) => {
    if (workOrder) {
      const { work_order_id: workOrderId, work_order_title: title } = workOrder;

      activity = await createActivity(
        dispatch,
        createActivityReqObj(rows[0], { workOrderId, title })
      );

      if (activity)
        exucations = await saveExucationSpecification(
          selectedSpecifications,
          workOrderId
        );

      if (!activity || !exucations) {
        setSubmitting(false);
        setIsEditingAllowed(true);
        return null;
      }

      finish2();
    }

    if (workOrder) showSuccessNotification('Work Order successfully created');

    if (activity) showSuccessNotification('Tasks successfully created');

    if (exucations)
      showSuccessNotification('Exucation Specification successfully saved');

    setSubmitting(false);
    setIsEditingAllowed(true);

    return undefined;
  };

  const onDeviceChange = async (e, setFieldValue) => {
    switch (e.target.value) {
      case 1:
        fetchRTTubes();
        setFieldValue(rtDeviceType, e.target.value);
        setYSourceData(null);
        setFieldValue(ySource, undefined);
        setFieldValue(transport, false);
        setFieldValue(shots, undefined);
        break;
      case 2:
        fetchYSource();
        setNumbers(null);
        setVoltages(null);
        setFieldValue(rtDeviceType, e.target.value);
        setFieldValue(voltage, undefined);
        setFieldValue(rtTubeId, undefined);
        setFieldValue(shots, undefined);
        break;
      default: {
        setNumbers(null);
        setVoltages(null);
        setYSourceData(null);
        return null;
      }
    }
    return null;
  };

  const onYSourceChange = e => {
    if (e.target.value) setNumbers(ySourceData[e.target.value]);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {({
        values,
        setSubmitting,
        setFieldValue,
        setErrors,
        setTouched,
        validateForm,
        isSubmitting,
      }) => {
        const {
          [rtDeviceType]: device,
          [transport]: transportChecked,
          [ySource]: source,
        } = values;

        return (
          <>
            <Section>RT Registration</Section>
            <FlexDiv>
              <MarginRight>
                <SFormSectionTitle>{inspector}</SFormSectionTitle>
                <SelectAnt
                  placeholder="Search"
                  showSearch
                  name={inspector}
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                  disabled={isSubmitting}
                >
                  {simpleEmployees.map(({ fullName, id }) => (
                    <Option key={id} value={id}>
                      {fullName}
                    </Option>
                  ))}
                </SelectAnt>
                <SpecificError>
                  <ErrorMessage name={inspector} />
                </SpecificError>
              </MarginRight>

              <MarginRight>
                <SFormSectionTitle>{rtDeviceType}</SFormSectionTitle>
                <Radio.Group
                  disabled={isSubmitting}
                  onChange={e => onDeviceChange(e, setFieldValue)}
                  name={rtDeviceType}
                >
                  <Radio value={1}>RT Tube</Radio>
                  <Radio value={2}>y Source</Radio>
                </Radio.Group>
                <SpecificError>
                  <ErrorMessage name={rtDeviceType} />
                </SpecificError>
              </MarginRight>

              {ySourceData && (
                <FlexItems>
                  <MarginRight>
                    <Radio.Group
                      disabled={isSubmitting}
                      name={ySource}
                      onChange={onYSourceChange}
                    >
                      {Object.keys(ySourceData).map(s => {
                        return (
                          <Radio key={s} value={s}>
                            {s}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                    <SpecificError>
                      <ErrorMessage name={ySource} />
                    </SpecificError>
                  </MarginRight>

                  <Checkbox
                    disabled={isSubmitting}
                    onChange={fetchEstablishment}
                    name={transport}
                  >
                    {transport}
                  </Checkbox>
                </FlexItems>
              )}

              {device && numbers && (
                <FlexItems>
                  {device === 1 && (
                    <MarginRight>
                      <SFormSectionTitle>Set Number</SFormSectionTitle>
                      <SelectAnt
                        disabled={isSubmitting}
                        placeholder="Search"
                        showSearch
                        name={rtTubeId}
                        showArrow={false}
                        defaultActiveFirstOption={false}
                        notFoundContent={null}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .includes(input.toLowerCase().trim())
                        }
                      >
                        {numbers &&
                          numbers.map(({ name, id }) => (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          ))}
                      </SelectAnt>

                      <SpecificError>
                        <ErrorMessage name={rtTubeId} />
                      </SpecificError>
                    </MarginRight>
                  )}

                  {device === 1 && (
                    <MarginRight>
                      <SFormSectionTitle>{voltage}</SFormSectionTitle>
                      <SelectAnt
                        disabled={isSubmitting}
                        placeholder="Search"
                        showSearch
                        name={voltage}
                        showArrow={false}
                        defaultActiveFirstOption={false}
                        notFoundContent={null}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .includes(input.toLowerCase().trim())
                        }
                      >
                        {voltages &&
                          voltages.map(v => (
                            <Option key={v} value={v}>
                              {v}
                            </Option>
                          ))}
                      </SelectAnt>

                      <SpecificError>
                        <ErrorMessage name={voltage} />
                      </SpecificError>
                    </MarginRight>
                  )}

                  {device === 1 && (
                    <MarginRight>
                      <SFormSectionTitle>{shots}</SFormSectionTitle>
                      <InputNumber
                        disabled={isSubmitting}
                        placeholder={shots}
                        name={shots}
                      />
                      <SpecificError>
                        <ErrorMessage name={shots} />
                      </SpecificError>
                    </MarginRight>
                  )}
                </FlexItems>
              )}
            </FlexDiv>

            <FlexItems>
              {!!(device === 2 && !!source) && (
                <FlexItems>
                  <MarginRight>
                    <SFormSectionTitle>Set Number</SFormSectionTitle>
                    <SelectAnt
                      disabled={isSubmitting}
                      placeholder="Search"
                      showSearch
                      name={gammaSourceId}
                      showArrow={false}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      {numbers &&
                        numbers.map(({ set_number: name, id }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                    </SelectAnt>
                    <SpecificError>
                      <ErrorMessage name={gammaSourceId} />
                    </SpecificError>
                  </MarginRight>

                  <MarginRight>
                    <SFormSectionTitle>{shots}</SFormSectionTitle>
                    <InputNumber
                      disabled={isSubmitting}
                      placeholder={shots}
                      name={shots}
                    />
                    <SpecificError>
                      <ErrorMessage name={shots} />
                    </SpecificError>
                  </MarginRight>
                </FlexItems>
              )}

              {transportChecked && (
                <FlexItems>
                  <MarginRight>
                    <SFormSectionTitle>{from}</SFormSectionTitle>
                    <SelectAnt
                      disabled={isSubmitting}
                      placeholder="Search"
                      showSearch
                      name={from}
                      showArrow={false}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      {establishments &&
                        establishments.map(({ name, id }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                    </SelectAnt>

                    <SpecificError>
                      <ErrorMessage name={from} />
                    </SpecificError>
                  </MarginRight>

                  <MarginRight>
                    <SFormSectionTitle>{to}</SFormSectionTitle>
                    <SelectAnt
                      disabled={isSubmitting}
                      placeholder="Search"
                      showSearch
                      name={to}
                      showArrow={false}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      {establishments &&
                        establishments.map(({ name, id }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                    </SelectAnt>
                    <SpecificError>
                      <ErrorMessage name={to} />
                    </SpecificError>
                  </MarginRight>
                </FlexItems>
              )}
            </FlexItems>

            <StyledFlexBeetwen>
              <Button type="dashed" disabled={isSubmitting} onClick={exit}>
                Exit
              </Button>
              <TwoBtnWrapper>
                <Button disabled={isSubmitting} onClick={prev}>
                  Back
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={async () => {
                    let activity;
                    let exucations;
                    const errors = await validateForm().then(res => {
                      if (Object.keys(res).length) {
                        setErrors(res);
                        setTouched(res);
                        return true;
                      }
                    });

                    if (errors) return null;

                    setSubmitting(true);
                    setIsEditingAllowed(false);

                    if (!workOrderData) {
                      const workOrder = await handleSubmit(
                        createRequestObj(values, step1Data)
                      );
                      setWorkOrderData(workOrder);
                      createNewWorkOrder(
                        workOrder,
                        values,
                        activity,
                        exucations,
                        setSubmitting
                      );
                    } else
                      createNewWorkOrder(
                        workOrderData,
                        values,
                        activity,
                        exucations,
                        setSubmitting
                      );
                  }}
                  disabled={isSubmitting}
                >
                  Create Work Order
                </Button>
              </TwoBtnWrapper>
            </StyledFlexBeetwen>
          </>
        );
      }}
    </Formik>
  );
};

export default RadiographicFormik;

const Section = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 25px;
  margin-top: 15px;
`;

export const SFormSectionTitle = styled.h4`
  font-weight: 500;
  padding: 5px 0px;
`;

const SelectAnt = styled(Select)`
  width: 200px;
`;

const FlexItems = styled.div`
  margin-top: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const MarginRight = styled.div`
  margin-right: 25px;
  position: relative;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledFlexBeetwen = styled(FlexBeetwen)`
  padding-top: 30px;
`;

const SpecificError = styled.div`
  position: absolute;
  bottom: 0px;
  transform: translateY(120%);
  min-height: 5px;
  ${errorStyles}
`;
