import 'twix';
import styled from 'styled-components';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { useRef, useEffect, useState, useCallback } from 'react';

import API from '../../api';
import {
  OFFSET_DAY_WIDTH,
  DAY_TITLE_HEIGHT,
  TOP_PANEL_HEIGHT,
} from '../../constants/continuum';
import { border } from '../../constants/stylesConstants';
import { addTask } from '../../coreFuncs/interaction';
import globalMutableStore from '../../context/globalMutableStore';
import { useRootContext } from '../../context';
import { DATE_FORMAT, MOMENT_DATE_TIME_FORMAT } from '../../constants/formats';
import {
  zoomIn,
  zoomOut,
  resetScale,
  addTodayRef,
  deleteAllFiles,
} from '../../context/actions';
import Day from './Day';
import TaskBlock from './TaskBlock';
import NewEventModal from '../ModalWindows/NewEventModal';
import {
  handleServerError,
  showWarnNotification,
} from '../../utils/errorHandlers';
import { fileLoadingWarnMsg } from '../../constants/errorsMsgs';

export default function Calendar({ board, grid }) {
  const {
    state: {
      scale,
      dates,
      employees,
      activities,
      dimensions,
      isEditable,
      isUpdateAll,
      positionsMap,
      isFileLoading,
      attachedFiles,
      allowedEmployees,
      workOrdersSearchQuery,
      workOrderLocationFilters,
      workOrderCustomerFilters,
      workOrderEstablishmentFilters,
    },
    dispatch,
  } = useRootContext();

  const todayRef = useRef(null);

  const today = moment().format(DATE_FORMAT);
  const zeroDate = dates.length && dates[0].date;
  const calendarWidth = dates.length * OFFSET_DAY_WIDTH;

  const [eventModalContent, setEventModalContent] = useState(null);

  const isVisibleActivity = (workOrderId, establishment, customer, city) => {
    let isMatchedByQuery = true;
    let isMatchedByLocation = true;
    let isMatchedByCustomer = true;
    let isMatchedByEstablishment = true;

    if (workOrderId) {
      isMatchedByQuery =
        !workOrdersSearchQuery ||
        establishment.toLowerCase().includes(workOrdersSearchQuery) ||
        customer.toLowerCase().includes(workOrdersSearchQuery) ||
        city.toLowerCase().includes(workOrdersSearchQuery);
      isMatchedByLocation =
        !workOrderLocationFilters.length ||
        workOrderLocationFilters.includes(city);
      isMatchedByCustomer =
        !workOrderCustomerFilters.length ||
        workOrderCustomerFilters.includes(customer);
      isMatchedByEstablishment =
        !workOrderEstablishmentFilters.length ||
        workOrderEstablishmentFilters.includes(establishment);
    }

    const isActivityVisible =
      isMatchedByQuery &&
      isMatchedByLocation &&
      isMatchedByCustomer &&
      isMatchedByEstablishment;

    return isActivityVisible;
  };

  const openNewEventModal = useCallback(
    async ({ start, end, employeeIndex }) => {
      await setEventModalContent({
        modalType: 'new',
        employeeId: employees[employeeIndex].id,
        dates: {
          end: end.format(MOMENT_DATE_TIME_FORMAT),
          start: start.format(MOMENT_DATE_TIME_FORMAT),
        },
      });
    },
    [employees]
  );

  const closeNewEventModal = async () => {
    if (attachedFiles.length) {
      const requests = attachedFiles.map(file =>
        API.deleteUploadFile(file.fileId)
      );

      try {
        await Promise.all(requests);
        deleteAllFiles(dispatch);
      } catch (e) {
        handleServerError(e);
      }
    }

    setEventModalContent(null);
  };

  const [debouncedZoom] = useDebouncedCallback(deltaY => {
    if (deltaY < 0) zoomIn(dispatch);
    else zoomOut(dispatch);
  }, 50);

  useEffect(() => {
    const wheelHandler = e => {
      if (e.ctrlKey) {
        e.preventDefault();
        debouncedZoom(e.deltaY);
      }
    };

    const ctrlZeroHandler = e => {
      if (e.ctrlKey && e.key === '0') {
        resetScale(dispatch);
      }
    };

    if (grid.current) {
      grid.current.addEventListener('wheel', wheelHandler, {
        passive: false,
      });
    }

    document.addEventListener('keydown', ctrlZeroHandler);

    return () => {
      if (grid.current) {
        grid.current.removeEventListener('wheel', wheelHandler);
      }
      document.removeEventListener('keydown', ctrlZeroHandler);
    };
  }, [grid.current]);

  useEffect(() => {
    addTodayRef(dispatch, todayRef);
    globalMutableStore.zeroDate = zeroDate;
  }, [zeroDate, todayRef]);

  return (
    <Container id="calendar" ref={grid}>
      {dates &&
        dates.map(date => (
          <Day
            key={date.date}
            date={date}
            scale={scale}
            todayRef={
              today === moment(date.date).format(DATE_FORMAT) ? todayRef : null
            }
            dayClass={moment(date.date).format(DATE_FORMAT)}
          />
        ))}

      <BlocksWrapper
        onMouseDown={e => {
          if (isEditable) {
            e.stopPropagation();
            addTask({
              eDown: e,
              employees,
              dimensions,
              board: board.current,
              zeroDate: dates[0].date,
              callback: openNewEventModal,
              allowedEmployees,
              isUpdateAll,
              grid: grid.current,
            });
          }
        }}
      >
        {activities &&
          activities.map(
            ({
              id,
              type,
              city,
              note,
              color,
              border_bottom_color: borderBottomColor,
              title,
              description,
              customer_id: customerId,
              work_location_id: workLocationId,
              end_point: endPoint,
              start_point: startPoint,
              employee_id: employeeId,
              customer_name: customer,
              project_number: project,
              work_order_id: workOrderId,
              establishment_name: establishment,
              recurring_activity_id: recurrenceId,
            }) => {
              if (typeof positionsMap[employeeId] !== 'number') {
                return null;
              }

              const isHidden = isVisibleActivity(
                workOrderId,
                establishment,
                customer,
                city
              );

              if (!isHidden) {
                return null;
              }

              return (
                <TaskBlock
                  key={id}
                  id={id}
                  type={type}
                  city={city}
                  board={board}
                  color={color}
                  borderBottomColor={borderBottomColor}
                  title={title}
                  project={project}
                  customer={customer}
                  dispatch={dispatch}
                  zeroDate={zeroDate}
                  endPoint={endPoint}
                  employees={employees}
                  startPoint={startPoint}
                  dimensions={dimensions}
                  customerId={customerId}
                  description={description}
                  workOrderId={workOrderId}
                  workOrderNote={note}
                  recurrenceId={recurrenceId}
                  calendarWidth={calendarWidth}
                  workLocationId={workLocationId}
                  employeePosition={positionsMap[employeeId]}
                />
              );
            }
          )}

        {eventModalContent && (
          <NewEventModal
            hideEventModal={() => setEventModalContent(null)}
            close={
              !isFileLoading
                ? closeNewEventModal
                : () => showWarnNotification(fileLoadingWarnMsg)
            }
            activityData={eventModalContent}
          />
        )}
      </BlocksWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  border-bottom: ${border};
`;

const BlocksWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  min-height: calc(100vh - ${TOP_PANEL_HEIGHT + DAY_TITLE_HEIGHT}px);
  top: 0;
  left: 0;
`;
