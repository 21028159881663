import * as React from 'react';

import MomentDatePicker from './MomentDatePicker';

const MomentTimePicker = React.forwardRef((props, ref) => (
  <MomentDatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

MomentTimePicker.displayName = 'TimePicker';

export default MomentTimePicker;
