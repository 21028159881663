import styled, { css } from 'styled-components';
import { Upload, message } from 'antd';
import { FaUpload } from 'react-icons/fa';
import { useRootContext } from '../../../../context';
import { addFile, deleteFile } from '../../../../context/actions';
import { GeneralError } from '../../../common/Messages';

const { Dragger } = Upload;

const fileType = {
  DOCUMENT: 1,
  PURCHASE_ORDER: 2,
  REQUEST: 4,
};

const Attachments = () => {
  const {
    dispatch,
    state: { attachedFiles, isFileLoading },
  } = useRootContext();
  const typeCount = attachedFiles.reduce((acc, curr) => {
    acc[curr.fileType] = (acc[curr.fileType] || 0) + 1;
    return acc;
  }, {});

  const initialProps = {
    multiple: false,
    customRequest({ file, filename, onProgress, onSuccess }) {
      const { uid, name } = file;
      const formData = new FormData();
      formData.append('document', file);
      formData.append('document_type', fileType[filename]);
      addFile(dispatch, formData, name, uid, filename, onProgress, onSuccess);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(targetFile) {
      const { uid } = targetFile;
      const removingFile = attachedFiles.find(file => file.uid === uid);
      deleteFile(dispatch, removingFile.fileId);
    },
    showUploadList: {
      showRemoveIcon: true,
    },
  };

  const fileList = attachedFiles.reduce(
    (acc, { fileType: fType, uid, name }) => {
      if (!acc[fType]) {
        acc[fType] = [];
        acc[fType].push({
          uid,
          name,
        });
      } else {
        acc[fType].push({
          uid,
          name,
        });
      }
      return acc;
    },
    {}
  );

  return (
    <Container isFileLoading={isFileLoading}>
      <Title>Attachments</Title>

      <PurchaseContainer isLimit={typeCount.PURCHASE_ORDER >= 1}>
        <SubTitle>Purchase order</SubTitle>

        {typeCount.PURCHASE_ORDER >= 1 && (
          <GeneralError>You cannot load another purchase order.</GeneralError>
        )}

        <Dragger
          defaultFileList={fileList.PURCHASE_ORDER}
          name="PURCHASE_ORDER"
          {...initialProps}
        >
          <DnDIcon />
          Drag and drop files or click to select
        </Dragger>
      </PurchaseContainer>

      <UploadContainer>
        <SubTitle>Requests</SubTitle>
        <Dragger
          defaultFileList={fileList.REQUEST}
          name="REQUEST"
          {...initialProps}
        >
          <DnDIcon />
          Drag and drop files or click to select
        </Dragger>
      </UploadContainer>

      <UploadContainer>
        <SubTitle>Documents</SubTitle>
        <Dragger
          defaultFileList={fileList.DOCUMENT}
          name="DOCUMENT"
          {...initialProps}
        >
          <DnDIcon />
          Drag and drop files or click to select
        </Dragger>
      </UploadContainer>
    </Container>
  );
};

export default Attachments;

const Container = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isFileLoading',
})`
  position: relative;
  padding: 0 20px 20px 20px;
  height: 300px;
  overflow: auto;
  flex-grow: 1;
  .ant-upload-list-item-card-actions {
    display: ${p => (p.isFileLoading ? 'none' : 'inline')};
  }
`;

const Title = styled.h1`
  position: sticky;
  top: 0;
  color: #2c9ef5;
  font-size: 22px;
  padding: 20px 0 25px 0;
  text-align: center;
  background-color: #fafafa;
`;

const SubTitle = styled.span`
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const PurchaseContainer = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isLimit',
})`
  margin-bottom: 10px;
  & > span {
    display: block;
  }
  ${p =>
    p.isLimit
      ? css`
          .ant-upload,
          .ant-upload-btn {
            display: none;
            visibility: hidden;
          }
        `
      : null}
`;

const UploadContainer = styled.div`
  margin-bottom: 10px;
  & > span {
    display: block;
  }
`;

const DnDIcon = styled(FaUpload)`
  path {
    fill: #2c9ef5;
  }
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;
