import moment from 'moment';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
} from '../constants/formats';

export const getRequestDateFormat = date => {
  return moment(date).format(DATE_FORMAT);
};

export const getDiffInMinutes = ({
  endDate,
  endTime,
  startDate,
  startTime,
}) => {
  const end = moment(
    `${endDate.format(DATE_FORMAT)} ${endTime.format(TIME_FORMAT)}`,
    DATE_TIME_FORMAT
  );
  const start = moment(
    `${moment(startDate).format(DATE_FORMAT)} ${startTime.format(TIME_FORMAT)}`,
    DATE_TIME_FORMAT
  );

  return end.diff(start, 'minutes');
};

export const areObjectsTheSame = (usedDates, initialDates) => {
  return Object.keys(usedDates).every(key => {
    if (
      typeof usedDates[key] === 'object' &&
      moment(usedDates[key]).format(DATE_TIME_FORMAT) ===
        moment(initialDates[key]).format(DATE_TIME_FORMAT)
    ) {
      return true;
    }

    if (usedDates[key] === initialDates[key]) return true;
    return false;
  });
};

export const getDisabledStartDate = (startValue, endDate) => {
  if (!startValue || !endDate) return false;

  return moment(startValue).valueOf() > moment(endDate).valueOf();
};

export const getDisabledEndDate = (endValue, startDate) => {
  if (!endValue || !startDate) return false;
  // return moment(endValue).valueOf() < moment(startDate).valueOf();
  return moment(endValue).isBefore(moment(startDate));
};

export const onChangeDateTime = (value, fieldName, setValue) => {
  if (!!value) setValue(fieldName, moment(value));
};

export const onChangStartDate = (
  startDate,
  endDate,
  startFieldName,
  endFieldName,
  setValue,
  prevStartDate
) => {
  if (!!startDate) {
    if (startDate > endDate) {
      setValue(startFieldName, moment(startDate));
      setValue(endFieldName, moment(startDate));
    } else setValue(startFieldName, moment(startDate));
  } else setValue(startFieldName, prevStartDate);
};
