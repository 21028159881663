import React from 'react';
import styled from 'styled-components';
import { Select, Button } from 'antd';

import { FlexBeetwen } from '../../../common/styledComponents';
import { CenterAntSpin } from '../../../common/Spinners';
import { filterOptions } from '../../../../utils/search';

const { Option } = Select;
const customer = 'Customer';

export default ({
  exit,
  next,
  customers,
  customerId,
  setCustomerId,
  isNewCustomer,
  setIsNewCustomer,
}) => {
  const [query, setQuery] = React.useState('');

  return (
    <Container>
      {customers ? (
        <WideSelect
          size="large"
          showSearch
          placeholder={customer}
          name={customer}
          filterOption={false}
          notFoundContent={null}
          defaultValue={typeof customerId === 'number' ? customerId : undefined}
          onSearch={value => setQuery(value)}
          onChange={value => setCustomerId(value)}
        >
          {filterOptions(customers, query, 'name').map(({ name, id }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </WideSelect>
      ) : (
        <CenterAntSpin />
      )}

      <FlexBeetwen>
        <Button type="dashed" onClick={exit}>
          Exit
        </Button>
        <Button
          type="primary"
          disabled={!customerId}
          onClick={() => {
            if (isNewCustomer) setIsNewCustomer(false);
            next();
          }}
        >
          Next
        </Button>
      </FlexBeetwen>
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
`;

export const WideSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
`;
