import { Tabs } from 'antd';

import NewProject from './NewProject';
import ProjectSelector from './ProjectSelector';

export default ({
  exit,
  prev,
  next,
  customerId,
  projects,
  projectId,
  establishments,
  setEstablishmentId,
  customerContacts,
  projectOptions,
  preparePropsForWorkOrder,
  isNewCustomer,
}) => {
  const isSelectorShown = !isNewCustomer && projects && !!projects.length;

  const items = [
    {
      key: '1',
      label: 'Select existing project',
      disabled: !isSelectorShown,
      children: (
        <ProjectSelector
          exit={exit}
          prev={prev}
          next={next}
          projects={projects}
          projectId={projectId}
          preparePropsForWorkOrder={preparePropsForWorkOrder}
        />
      ),
    },
    {
      key: '2',
      label: 'Create new project',
      children: (
        <NewProject
          exit={exit}
          prev={prev}
          next={next}
          customerId={customerId}
          establishments={establishments}
          setEstablishmentId={setEstablishmentId}
          customerContacts={customerContacts}
          projectOptions={projectOptions}
          preparePropsForWorkOrder={preparePropsForWorkOrder}
        />
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey={isSelectorShown ? '1' : '2'} items={items} />
    </div>
  );
};
