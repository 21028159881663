import React from 'react';
import styled from 'styled-components';
import {
  basic,
  danger,
  obscure,
  primary,
  transition,
} from '../../constants/stylesConstants';

import SaveSvg from '../../public/icons/save.svg';
import TrashSvg from '../../public/icons/trash.svg';
// import TransferSvg from '../../public/icons/transfer.svg';

const BtnBase = styled.button`
  font-size: 14px;
  font-weight: 500;
  color: ${basic};
  outline: none;
  border: none;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const BigBtn = styled(BtnBase)`
  width: fit-content;
  height: 50px;
  border: 2px solid white;
  border-radius: 4px;
  background: ${primary};
  text-transform: uppercase;
  font-size: 18px;
  padding: 0 10px;
  width: 100%;

  &:hover {
    background: ${basic};
    color: ${primary};
    transition: ${transition};
    border: 2px solid ${primary};
  }
`;

export const BigWarnBtn = styled(BigBtn)`
  background: ${basic};
  color: ${danger};
  border: 2px solid ${danger};
  &:hover {
    background: ${danger};
    color: ${basic};
    transition: ${transition};
    border: none;
  }
`;

export const PrimaryBtn = styled(BtnBase)`
  background: ${primary};
`;

export const PlanBtn = styled(PrimaryBtn)`
  padding: 8px 10px;
  font-size: 9px;
  border-radius: 3px;
`;

// export const ChangeDateBtn = styled(PrimaryBtn)`
//   padding: 9px 20px;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const DeletePlanningBtn = styled(BtnBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${basic};
  color: ${danger};
  border-radius: 3px;
  border: 2px solid #e36969;
  padding: 9px 20px;
`;

// export const ShowInMapsBtn = styled(PrimaryBtn)`
//   padding: 5px 11px;
//   font-weight: 400;
//   border-radius: 2px;
//   flex-shrink: 0;
// `;

// export const ViewAllActivitiesBtn = styled(PrimaryBtn)`
//   width: fit-content;
//   padding: 5px;
//   font-weight: 400;
// `;

export const SaveBtn = styled(PrimaryBtn).withConfig({
  shouldForwardProp: prop => prop !== 'isDelete', // Filter out isDelete
})`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 4px;
  padding: 9px 20px;
  background: ${({ isDelete }) => (isDelete ? danger : primary)};
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const CancelBtn = styled(BtnBase)`
  font-size: 16px;
  text-decoration: underline;
  color: ${obscure};
  background: ${basic};
`;

// const TransferIcon = styled(TransferSvg)`
//   margin-right: 10px;
// `;

const TrashIcon = styled(TrashSvg)`
  margin-right: 10px;
`;

const SaveIcon = styled(SaveSvg)`
  margin-right: 8px;
`;

// export const ChangeDateBtnWithIcon = ({ children }) => {
//   return (
//     <ChangeDateBtn>
//       <TransferIcon />
//       {children}
//     </ChangeDateBtn>
//   );
// };

export const DeletePlanningBtnWithIcon = ({
  children,
  type,
  openDeleteModal,
}) => {
  return (
    <DeletePlanningBtn type={type} onClick={openDeleteModal}>
      <TrashIcon />
      {children}
    </DeletePlanningBtn>
  );
};

export const SaveBtnWithIcon = ({
  disabled,
  type,
  children,
  isDelete,
  onClick,
}) => {
  return (
    <SaveBtn
      type={type}
      disabled={disabled}
      onClick={onClick}
      isDelete={isDelete}
    >
      <SaveIcon />
      {children}
    </SaveBtn>
  );
};
