import { Tabs } from 'antd';

import NewCustomer from './NewCustomer';
import CustomerSelector from './CustomerSelector';

export default ({
  exit,
  next,
  customers,
  pushNewCustomer,
  customerId,
  setCustomerId,
  isNewCustomer,
  setIsNewCustomer,
}) => {
  const items = [
    {
      key: '1',
      label: 'Select existing customer',
      children: (
        <CustomerSelector
          exit={exit}
          next={next}
          customers={customers}
          customerId={customerId}
          setCustomerId={setCustomerId}
          isNewCustomer={isNewCustomer}
          setIsNewCustomer={setIsNewCustomer}
        />
      ),
    },
    {
      key: '2',
      label: 'Create new customer',
      children: (
        <NewCustomer
          exit={exit}
          next={next}
          setCustomerId={setCustomerId}
          pushNewCustomer={pushNewCustomer}
          isNewCustomer={isNewCustomer}
          setIsNewCustomer={setIsNewCustomer}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};
