import React from 'react';

import styled from 'styled-components';
import { Input, Popconfirm } from 'antd';

import { ClipLoader } from 'react-spinners';

import { PlusOutlined } from '@ant-design/icons';
import { GrEdit } from 'react-icons/gr';

import t from '../../../context/actionsTypes';
import API from '../../../api';
import SaveSvg from '../../../public/icons/save.svg';
import TrashSvg from '../../../public/icons/trash.svg';
import SmileIcon from '../../common/Icon';
import { danger } from '../../../constants/stylesConstants';
import { useRootContext } from '../../../context';
import {
  handleServerError,
  showSuccessNotification,
  showWarnNotification,
} from '../../../utils/errorHandlers';

export default function Note({ workOrderData, updateWorkOrderData }) {
  const { dispatch } = useRootContext();

  const inputRef = React.useRef(null);

  const entityId = workOrderData.activity.work_order_id;
  const entityType = 'work_order';
  const entityNote = workOrderData?.activity?.note;

  const [noteText, setNoteText] = React.useState(
    workOrderData?.activity?.note?.content || ''
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const toggleCreating = () => setIsCreating(prev => !prev);

  const updateHandler = async () => {
    if (!noteText.trim().length) {
      return showWarnNotification('Empty value is not allowed to be saved!');
    }

    try {
      setIsLoading(true);

      if (!isEditing) {
        const payload = {
          content: noteText,
          is_sticky: 'true',
          entity_id: entityId,
          entity_type: entityType,
        };

        const savedNote = await API.saveNote(payload);

        if (savedNote) {
          updateWorkOrderData('activity.note', savedNote);
          dispatch({
            type: t.EDIT_ACTIVITY,
            payload: {
              id: workOrderData.activity.id,
              updatedActivity: {
                ...workOrderData.activity,
                note: savedNote,
              },
            },
          });

          showSuccessNotification('Note successfully created!');
        }
      } else {
        const payload = {
          ...entityNote,
          content: noteText,
          is_sticky: 'true',
          entity_type: entityType,
        };

        const res = await API.editNote(payload);

        if (res.status === 200) {
          setIsEditing(false);
          updateWorkOrderData('activity.note', payload);
          dispatch({
            type: t.EDIT_ACTIVITY,
            payload: {
              id: workOrderData.activity.id,
              updatedActivity: {
                ...workOrderData.activity,
                note: payload,
              },
            },
          });
          showSuccessNotification('Note successfully updated!');
        }
      }
    } catch (err) {
      handleServerError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteHandler = async () => {
    try {
      setIsLoading(true);

      const payload = {
        id: entityNote.id,
      };

      const res = await API.deleteNote(payload);

      if (res.status === 200) {
        setNoteText('');
        updateWorkOrderData('activity.note', null);
        dispatch({
          type: t.EDIT_ACTIVITY,
          payload: {
            id: workOrderData.activity.id,
            updatedActivity: {
              ...workOrderData.activity,
              note: null,
            },
          },
        });
        setIsCreating(false);
        showSuccessNotification('Note successfully deleted!');
      }
    } catch (err) {
      handleServerError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!entityNote && !isCreating) {
    return (
      <New onClick={toggleCreating}>
        <PlusOutlined style={{ marginRight: '5px' }} />
        <span>Note</span>
      </New>
    );
  }

  return (
    <>
      <Container>
        <Input
          ref={inputRef}
          value={noteText}
          variant="borderless"
          readOnly={entityNote && entityNote.id && !isEditing}
          showCount
          title="Use pencil icon to edit"
          maxLength={250}
          placeholder="Put yor notes here"
          onChange={e => {
            setNoteText(e.target.value.replace(/ {2,}/g, ' ')); // allow only 1 space character
          }}
        />

        <NoteActions>
          {!isLoading && (
            <>
              {(!entityNote?.id || isEditing) && (
                <SaveIcon onClick={updateHandler} />
              )}

              {entityNote?.id && !isEditing && (
                <GrEdit
                  id="edit-icon"
                  onClick={() => {
                    setIsEditing(true);
                    inputRef.current.focus();
                  }}
                />
              )}

              <Popconfirm
                icon={SmileIcon}
                title="Are you sure you want to delete this note?"
                okText="Yes"
                cancelText="No"
                onConfirm={deleteHandler}
              >
                {entityNote?.id && <TrashIcon />}
              </Popconfirm>
            </>
          )}

          {isLoading && <ClipLoader size={5} />}
        </NoteActions>
      </Container>
    </>
  );
}

const New = styled.div`
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #333333;
  cursor: pointer;
  border: 1px solid #daa520;
  font-size: 14px;
  border-radius: 6px;
  background-color: #fffacd;
`;

const Container = styled.div`
  height: 40px;
  min-width: 400px;
  display: flex;
  padding: 0 10px 0 5px;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  border: 1px solid #daa520;
  border-radius: 6px;
  background-color: #fffacd;
`;

const NoteActions = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }

  #edit-icon {
    path {
      fill: orange;
    }
  }
`;

const SaveIcon = styled(SaveSvg)`
  cursor: pointer;

  path {
    fill: green;
  }
`;

const TrashIcon = styled(TrashSvg)`
  cursor: pointer;
  margin-left: 15px;

  path {
    fill: ${danger};
  }
`;
