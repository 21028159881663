import styled from 'styled-components';

import { flexCenter } from '../common/styledComponents';

export default ({ onHideModal, children, background }) => (
  <Backdrop
    onMouseDown={e => e.stopPropagation()}
    onClick={onHideModal}
    background={background}
  >
    <Content onClick={e => e.stopPropagation()}>{children}</Content>
  </Backdrop>
);

const Content = styled.div`
  max-width: 1050px;
  width: fit-content;
`;

const Backdrop = styled.div`
  ${flexCenter};
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: ${p => p.background || 'rgba(0, 0, 0, 0.4)'};
`;
