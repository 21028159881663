import React from 'react';

const useClickAway = (elementId, callback) => {
  React.useEffect(() => {
    const handleClickOutside = event => {
      const element = document.getElementById(elementId);
      if (element && !element.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [elementId, callback]);
};

export default useClickAway;
