import Head from 'next/head';

import styled from 'styled-components';
import { Empty } from 'antd';
import NProgress from 'nprogress';

import { useState, useEffect, useRef } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import Calendar from '../components/ResourcePlanning/Calendar';
import Navigation from '../components/Navigation';
import UnplannedOrders from '../components/UnplannedOrders';
import EmployeesColumn from '../components/ResourcePlanning/EmployeesColumn';
import { useRootContext } from '../context';
import { NAVIGATION_HEIGHT } from '../constants/continuum';
import { getRequestDateFormat } from '../utils/datesUtils';
import {
  addBoardRef,
  getWorkOrders,
  getActivities,
  getActivitiesTypes,
  getWorkOrderFilters,
  getActivitiesAndEmployees,
  updateNpProgressStatus,
} from '../context/actions';

import API from '../api';
import isQERP from '../hooks/isQerp';
import { PageError } from '../components/common/Messages';
import { getScrollGap } from '../utils/scrollBoundaries';
import { CenterFadeLoader } from '../components/common/Spinners';

const daysStep = 7;
const rightOffset = 1;
const startProgress = 0.7;
const leftOffset = -daysStep;
const initLeftOffset = -daysStep;
const initialDaysNumber = daysStep * 3;

export default () => {
  const SyntScroll = new Event('syntscroll');

  const {
    state: {
      dates,
      boardRef,
      scale,
      isNpProgressActive,
      initScrollLeft: initSL,
      scrollLeft: sLeft,
      isEditable,
      isFirstResponse,
    },
    dispatch,
  } = useRootContext();

  const board = useRef();
  const grid = useRef(null);

  const [fetchError, setFetchError] = useState(null);
  const [isLeftReach, setIsLeftReach] = useState(false);
  const [isRightReach, setIsRightReach] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(false);

  const onReachRight = () => {
    setIsRightReach(true);

    NProgress.start();
    NProgress.set(startProgress);

    updateNpProgressStatus(dispatch, true);

    if (dates.length) {
      getActivities(
        dispatch,
        {
          date: getRequestDateFormat(dates[dates.length - 1].date),
          offset: rightOffset,
          days: daysStep,
        },
        setFetchError,
        true
      );
    }
  };

  const onReachLeft = () => {
    setIsLeftReach(true);

    NProgress.start();
    NProgress.set(startProgress);

    updateNpProgressStatus(dispatch, true);

    if (dates.length) {
      getActivities(
        dispatch,
        {
          date: getRequestDateFormat(dates[0].date),
          offset: leftOffset,
          days: daysStep,
        },
        setFetchError,
        false
      );
    }
  };

  const [handleHorizontalScroll] = useDebouncedCallback(event => {
    event.stopPropagation();

    if (!isNpProgressActive) {
      const scrollGap = getScrollGap(scale);

      if (board.current) {
        document.dispatchEvent(SyntScroll);

        const { scrollWidth, scrollLeft, offsetWidth } = board.current;
        const scrolledRight = scrollLeft + offsetWidth;

        if (
          !isLeftReach &&
          !isRightReach &&
          scrolledRight > scrollWidth - scrollGap
        ) {
          onReachRight();
        } else if (!isLeftReach && !isRightReach && scrollLeft <= scrollGap) {
          onReachLeft();
        }
      }
    }
  }, 200);

  const verifyUserAuthHandler = async () => {
    const { host } = window.location;
    const isProduction = host.startsWith('plan');

    let serverUrl = process.env.REACT_APP_SERVER_URL;

    if (isProduction) {
      const domain = host.slice(5);
      serverUrl = `https://${domain}`;
    }

    try {
      const data = await API.verifyUserAuth();

      if (data && data.user_id) {
        getWorkOrders(dispatch);
        getActivitiesTypes(dispatch);
        getActivitiesAndEmployees(
          dispatch,
          {
            days: initialDaysNumber,
            offset: initLeftOffset,
          },
          setFetchError
        );
      } else {
        // Router.replace(serverUrl);
        document.location.replace(serverUrl);
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401)
        // Router.replace(serverUrl);
        document.location.replace(serverUrl);
    }
  };

  useEffect(() => {
    verifyUserAuthHandler();
  }, []);

  useEffect(() => {
    getWorkOrderFilters(dispatch);

    if (isRightReach) {
      setIsRightReach(false);
      NProgress.done();
    }

    if (isLeftReach) {
      setIsLeftReach(false);
      NProgress.done();
    }

    if (isRightReach || isLeftReach) updateNpProgressStatus(dispatch, false);

    if (board.current && !isFirstRender && dates.length) {
      setIsFirstRender(true);
      board.current.scrollLeft = initSL * scale;
    }
  }, [dates, scale]);

  useEffect(() => {
    if (board.current && !isRightReach && sLeft) {
      board.current.scrollLeft = sLeft * scale;
    }
  }, [isRightReach, scale]);

  useEffect(() => {
    if (board.current && !isLeftReach) {
      board.current.scrollLeft = initSL * scale;
    }
  }, [isLeftReach, scale]);

  useEffect(() => {
    if (!boardRef) {
      if (board.current) addBoardRef(dispatch, board);
    }
  }, [dates, board]);

  if (fetchError) return <PageError>{fetchError}</PageError>;
  if (!dates || !dates.length) return <CenterFadeLoader />;

  if (!dates || (!dates.length && isFirstResponse))
    return (
      <CenterEmpty>
        <Empty
          description={<Text>No data to plote, check your permissions</Text>}
        />
      </CenterEmpty>
    );

  return (
    <>
      <Head>
        <title>
          {`Planning ${isQERP(window.location.host) ? 'QERP' : 'TERP'}`}
        </title>
      </Head>

      <Navigation />

      <Container>
        <ScrollingWrapper ref={board} onScroll={handleHorizontalScroll}>
          <ContentBlock>
            <EmployeesColumn />

            <Calendar board={board} grid={grid} />
          </ContentBlock>
        </ScrollingWrapper>

        {isEditable && <UnplannedOrders board={board} grid={grid} />}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  height: calc(100% - ${NAVIGATION_HEIGHT}px);
  overflow: hidden;
  position: relative;

  & > * {
    user-select: none;
  }
`;

const ScrollingWrapper = styled.div`
  overflow: scroll;
`;

const CenterEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    transform: scale(2);
  }
`;

const ContentBlock = styled.div`
  display: flex;
  align-items: stretch;
  height: fit-content;
  width: fit-content;
`;

const Text = styled.span`
  display: inline-block;
  font-size: 20px;
  margin-top: 40px;
`;
